<!--
 * @Author: dingguowei
 * @Date: 2023-06-06 15:00:22
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-27 10:29:01
-->
<template>
  <div class="home">
    <el-card class="top">
      <el-row>
        <el-select
          size="mini"
          style="width: 200px"
          v-model="missionId"
          placeholder="请选择所属任务"
        >
          <el-option
            :key="index"
            :label="item.title"
            :value="item.id"
            v-for="(item, index) in allprompts"
          >
          </el-option>
        </el-select>
      </el-row>
      <el-row style="display: flex; justify-content: space-between">
        <div
          class="item"
          style="
            background-color: #5874ef;
            box-shadow: 0px 0px 6px 0px rgba(88, 116, 239, 0.6);
          "
        >
          <div class="left">
            <p
              style="
                color: white;
                font-size: 14px;
                font-weight: 300;
                margin-top: 30px;
              "
            >
              笔记总数
            </p>
            <p
              style="
                font-size: 20px;
                line-height: 36px;
                font-weight: 700;
                color: #fff;
              "
            >
              {{ annexData.totalCount }}
            </p>
          </div>
          <img class="right" src="../../assets/home/home_1.png" alt="" />
        </div>
        <div
          class="item"
          style="
            background-color: #ffdbc3;
            box-shadow: 0px 0px 6px 0px rgba(255, 134, 55, 0.6);
          "
        >
          <div class="left">
            <p style="font-size: 14px; font-weight: 400; margin-top: 18px">
              爆文指标
            </p>
            <p style="font-size: 20px; line-height: 36px; font-weight: 600">
              {{ annexData.hotSet }}
            </p>
            <p style="font-size: 12px; margin-top: 10px">
              互动指标 {{ annexData.interactSet }}
            </p>
          </div>
          <img class="right" src="../../assets/home/home_2.png" alt="" />
        </div>
        <div
          class="item"
          style="
            background-color: #d1f6ef;
            box-shadow: 0px 0px 6px 0px rgba(110, 240, 136, 0.617);
          "
        >
          <div class="left">
            <p style="font-size: 14px; font-weight: 400; margin-top: 18px">
              有效笔记数
            </p>
            <p style="font-size: 20px; line-height: 36px; font-weight: 600">
              {{ annexData.validateCount }}
            </p>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <p
                style="
                  font-size: 12px;
                  display: flex;
                  padding-left: 5%;
                  line-height: 200%;
                "
              >
                <span>占比</span>
                <span style="margin-left: auto">{{
                  comstringpercentage(annexData.validatePercent)
                }}</span>
              </p>
              <el-progress
                color="#19D1B1"
                define-back-color="#ffffff"
                style="width: 90%; margin: auto"
                :show-text="false"
                :percentage="comnumpercentage(annexData.validatePercent)"
              ></el-progress>
            </div>
          </div>
          <img class="right" src="../../assets/home/home_3.png" alt="" />
        </div>
        <div
          class="item"
          style="
            background-color: #ddddf8;
            box-shadow: 0px 0px 6px 0px rgba(121, 110, 240, 0.617);
          "
        >
          <div class="left">
            <p style="font-size: 14px; font-weight: 400; margin-top: 18px">
              爆文数/总笔记数
            </p>
            <p style="font-size: 20px; line-height: 36px; font-weight: 600">
              {{ annexData.hotComplete }}/{{ annexData.totalCount }}
            </p>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <p
                style="
                  font-size: 12px;
                  display: flex;
                  padding-left: 5%;
                  line-height: 200%;
                "
              >
                <span>占比</span>
                <span style="margin-left: auto">{{
                  comstringpercentage(annexData.hotTotalPercent)
                }}</span>
              </p>
              <el-progress
                color="#5757DA"
                define-back-color="#ffffff"
                style="width: 90%; margin: auto"
                :show-text="false"
                :percentage="comnumpercentage(annexData.hotTotalPercent)"
              ></el-progress>
            </div>
          </div>
          <img class="right" src="../../assets/home/home_4.png" alt="" />
        </div>
        <div
          class="item"
          style="
            background-color: #fff2cc;
            box-shadow: 0px 0px 6px 0px rgba(220, 240, 110, 0.617);
          "
        >
          <div class="left">
            <p style="font-size: 14px; font-weight: 400; margin-top: 18px">
              爆文数/需求数
            </p>
            <p style="font-size: 20px; line-height: 36px; font-weight: 600">
              {{ annexData.hotComplete }}/{{ annexData.hotTotal }}
            </p>
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              "
            >
              <p
                style="
                  font-size: 12px;
                  display: flex;
                  padding-left: 5%;
                  line-height: 200%;
                "
              >
                <span>占比</span>
                <span style="margin-left: auto">{{
                  comstringpercentage(annexData.hotPercent)
                }}</span>
              </p>
              <el-progress
                color="#FFBF00"
                define-back-color="#ffffff"
                style="width: 90%; margin: auto"
                :show-text="false"
                :percentage="comnumpercentage(annexData.hotPercent)"
              ></el-progress>
            </div>
          </div>
          <img class="right" src="../../assets/home/home_5.png" alt="" />
        </div>
      </el-row>
      <div style="display: flex; flex-wrap: wrap">
        <div class="numitem" v-for="(item, index) in numitem" :key="index">
          <p style="font-size: 13px; font-weight: 400">
            {{ item.name }}
          </p>
          <p style="font-size: 20px; font-weight: 700; margin-top: 10px">
            {{ annexData[item.startfieId] }}/{{ annexData[item.endfieId] }}
          </p>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <p
              style="
                font-size: 12px;
                display: flex;

                line-height: 200%;
              "
            >
              <span>占比</span>
              <span
                style="margin-left: auto; font-weight: 600"
                :style="{ color: item.color }"
                >{{ comstringpercentage(annexData[item.percentage]) }}</span
              >
            </p>
            <el-progress
              :color="item.color"
              define-back-color="#EEEEEE"
              :show-text="false"
              :percentage="comnumpercentage(annexData[item.percentage])"
            ></el-progress>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="bottom">
      <div id="echarts" class="echarts"></div>
    </el-card>
  </div>
</template>

<script>
import { getList, backannex, annexlist } from "@/api/home";
import { loadmore } from "@/config/directives";
import moment from "moment";
import * as echarts from "echarts";
export default {
  components: {},
  directives: {
    loadmore,
  },
  data() {
    return {
      missionId: null,
      allprompts: [],
      promptpageNo: 1,
      numitem: [
        {
          name: "总KOL/总需求数",
          startfieId: "kolComplete",
          endfieId: "kolTotal",
          percentage: "kolPercent",
          color: "#5874EF",
        },
        {
          name: "KOL官方数/需求数",
          startfieId: "kolUpComplete",
          endfieId: "kolUpTotal",
          percentage: "kolUpPercent",
          color: "#5874EF",
        },
        {
          name: "KOL非官方/需求数",
          startfieId: "kolDownComplete",
          endfieId: "kolDownTotal",
          percentage: "kolDownPercent",
          color: "#5874EF",
        },
        {
          name: "总KOC/总需求数",
          startfieId: "kocComplete",
          endfieId: "kocTotal",
          percentage: "kocPercent",
          color: "#FF8637",
        },
        {
          name: "KOC官方/需求数",
          startfieId: "kocUpComplete",
          endfieId: "kocUpTotal",
          percentage: "kocUpPercent",
          color: "#FF8637",
        },
        {
          name: "KOC非官方/需求数",
          startfieId: "kocDownComplete",
          endfieId: "kocDownTotal",
          percentage: "kocDownPercent",
          color: "#FF8637",
        },
        {
          name: "素人/需求数",
          startfieId: "simpleComplete",
          endfieId: "simpleTotal",
          percentage: "simplePercent",
          color: "#19D1B1",
        },
        {
          name: "互动达标/需求数",
          startfieId: "interactComplete",
          endfieId: "interactTotal",
          percentage: "interactPercent",
          color: "#19D1B1",
        },
        {
          name: "PV/需求数",
          startfieId: "pvComplete",
          endfieId: "pvTotal",
          percentage: "pvPercent",
          color: "#19D1B1",
        },
        {
          name: "GMV/需求数",
          startfieId: "gmvComplete",
          endfieId: "gmvTotal",
          percentage: "gmvPercent",
          color: "#FE3231",
        },
        {
          name: "投流/需求数",
          startfieId: "flowComplete",
          endfieId: "flowTotal",
          percentage: "flowPercent",
          color: "#FE3231",
        },
        {
          name: "预算/需求数",
          startfieId: "budgetComplete",
          endfieId: "budgetTotal",
          percentage: "budgetPercent",
          color: "#FE3231",
        },
      ],
      annexData: [],
      echartsData: {
        xdata: [],
        ydata: [],
      },
    };
  },
  created() {},
  async mounted() {
    await this.getList();
    this.missionId = this.allprompts[0].id;
    window.addEventListener("resize", () => {
      this.initecharts();
    });
  },
  watch: {
    missionId: {
      handler() {
        this.$nextTick(() => {
          this.getbackannex();
          this.getechartsData();
        });
      },
      deep: true,
    },
  },
  computed: {
    comstringpercentage: function () {
      return function (params) {
        var f = (params * 100).toFixed(2);
        var s = f.toString();
        var rs = s.indexOf(".");
        if (rs < 0) {
          rs = s.length;
          s += ".";
        }
        while (s.length <= rs + 2) {
          s += "0";
        }
        return s + "%";
      };
    },
    comnumpercentage: function () {
      return function (params) {
        params = params * 100;
        if (params > 0 && params < 100) {
          return params;
        } else if (params > 100 || params === 100) {
          return 100;
        } else {
          return 0;
        }
      };
    },
  },
  methods: {
    // 所有的任务列表
    async getList() {
      await getList({ pageNo: this.promptpageNo, pageSize: 20 }).then((res) => {
        if (res.data.records.length > 0) {
          this.allprompts = [...this.allprompts, ...res.data.records];
          this.alloadMore = true;
        } else {
          this.alloadMore = false;
        }
      });
    },
    // 查看数据看板数据
    async getbackannex() {
      await backannex({ missionId: this.missionId }).then((res) => {
        this.annexData = res.data;
      });
    },
    async getechartsData() {
      this.echartsData.xdata = [];
      this.echartsData.ydata = [];
      await annexlist({
        startAt: moment(new Date())
          .subtract(29, "days")
          .format("yyyy-MM-DD 00:00:00"),
        endAt: moment(new Date()).format("yyyy-MM-DD 00:00:00"),
        missionId: this.missionId,
      }).then((res) => {
        res.data.map((item) => {
          this.echartsData.xdata.push(moment(item.createdOn).format("MM-DD"));
          this.echartsData.ydata.push(item.articleNum);
          this.initecharts();
        });
      });
    },
    // 初始化echarts
    async initecharts() {
      var chartDom = document.getElementById("echarts");
      let myChart = echarts.init(chartDom);
      var option = {
        title: {
          text: "近30天笔记数量",
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "white", //设置文字颜色
          },
          extraCssText:
            "width:150px;height:auto; border:none;background: linear-gradient(to right, #e4a0fe, #a097ff);color:#fff",
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: 10,
          top: 30,
          containLabel: true,
        },
        toolbox: {
          show: false,
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.echartsData.xdata,
          // name: xname,
          nameLocation: "middle",
          // x轴样式
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#666666",
              fontSize: "0.8rem",
            },
          },
          axisLabel: {
            formatter: function (value) {
              return value + "";
            },
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          // name: yname,
          // 居中
          nameLocation: "end",
          nameTextStyle: {
            padding: [0, 0, 0, 30], // 上右下左与原位置距离
          },
          // 坐标轴名称与轴线之间的距离。
          nameGap: 10,
          // y轴样式
          splitLine: {
            lineStyle: {
              type: "solid", // y轴分割线类型
              color: "#E5E6EB",
            },
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: "#909399 ",
            },
          },
        },
        series: [
          {
            name: "当日笔记数",
            type: "line",
            color: "#5874EF",
            // showSymbol: false,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 3, // 0.1的线条是非常细的了
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#5874EF ", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#ffffff", // 100% 处的颜色
                },
              ]),
            },
            data: this.echartsData.ydata,
          },
        ],
      };
      myChart.clear();
      option && myChart.setOption(option);
      myChart.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .top {
    margin: 10px 14px 0px;

    .item {
      margin: 10px 10px 0 0;
      height: 114px;
      display: flex;
      align-items: center;
      flex: 1;
      border-radius: 6px;
      .left {
        flex: 1;
        height: 100%;
        text-align: center;
      }
      .right {
        width: 64px;
        height: 64px;
        margin: 0 10px;
        border-radius: 50%;
      }
    }
    .numitem {
      margin-right: 1.16%;
      flex-basis: 15.5%;
      // flex-grow: 1;
      height: 100px;
      margin-top: 8px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      padding: 10px 16px;
      box-sizing: border-box;
    }
  }
  .bottom {
    margin: 10px 14px 10px;
    flex: 1;
    .echarts {
      height: 100%;
    }
  }
  ::v-deep .el-card {
    display: flex;
  }
  ::v-deep .el-card__body {
    flex: 1;
  }
}
</style>